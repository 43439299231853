import { ImageListItem } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Image from 'commons/Image';
import Text from 'commons/Text';
import { getLabelForOption } from 'configs/Constant';
import Routes from 'utils/Route';

const CollectionArtworkItem = ({ artwork }) => {
  const language = useSelector((state) => state?.language?.currentLanguage);

  const images = artwork?.images || [];
  const { size, medium } = artwork || {};

  const sizeData =
    size?.height &&
    size?.width &&
    `${size?.height} x ${size?.width} ${size?.depth ? ` x ${size?.depth}` : ''}cm `;

  return (
    <ImageListItem>
      <Link to={Routes.ArtworkDetail(artwork?.id)}>
        <Image src={images[0]} />

        <Text
          fontWeightMedium
          type="sm"
          mt={8}
        >
          {artwork?.title}
        </Text>

        <Text
          type="sm"
          mt={8}
        >
          {artwork?.artist_artwork?.artist_name}
        </Text>

        <Text
          type="xs"
          mt={8}
        >
          {getLabelForOption(medium, language)} {sizeData}
        </Text>
      </Link>
    </ImageListItem>
  );
};

export default CollectionArtworkItem;
