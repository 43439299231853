import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  btn: {
    '&.MuiButton-root': {
      minWidth: 93,
      padding: '7px 16px',
    },
  },
  btnNotAllow: {
    '&.MuiButton-root': {
      cursor: 'not-allowed',
    },
  },

  tabs: {
    '&.MuiTabs-root': {
      marginTop: 16,
      borderBottom: `1px solid ${Colors.Grey3}`,
    },
  },
  tab: {
    '&.MuiTab-root': {
      marginRight: 32,
      padding: 0,
      borderRadius: 99,
      fontSize: 16,
      lineHeight: '150%',
      textTransform: 'capitalize',
      alignItems: 'flex-start',
      minWidth: 'unset',
      '&.Mui-selected': {
        fontWeight: 500,
      },
    },
  },
  row: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formControl: {
    marginTop: 0,
  },
  flexItem: {
    marginTop: 0,
    flex: 1,
  },
  buttonDialog: {
    '&.MuiButton-root': {
      minWidth: 90,
      padding: '9px 16px',
      fontSize: '1.6rem',
      fontWeight: 400,
      lineHeight: '150%',
      letterSpacing: 0.64,
    },
  },
  textArtistNA: {
    marginTop: 116,
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default useStyles;
